<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong> System Users</strong></span>
      <v-btn color="primary" class="mr-0" @click="addUserModal = true"> Add User</v-btn>
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.name" label="Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3"  class="pl-0">
        <v-text-field label="Email" v-model="filter.email" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3"  class="pl-0">
        <v-text-field label="Designation" v-model="filter.designation" outlined dense hide-details></v-text-field>
      </v-col>
       <v-col cols="12" md="3"  class="pl-0 text-right">
        <v-btn color="primary" class="mr-2" @click="SystemUserSearchList()"> Search</v-btn>
        <v-btn color="primary"  @click="SystemUserList()">Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <v-btn color="primary" class="mr-8" @click="SystemUserList()">Refresh</v-btn>
        <v-btn color="primary" class="mr-8" @click="addUserModal = true"> Add User</v-btn>
      </v-col> -->
    </v-row>

    <v-data-table :headers="headers" :items="SystemList" :items-per-page="10"  fixed-header
      height="400" class="table-rounded">
      <template #[`item.operate`]="{item}">
        <v-btn color="primary" class="mr-3" @click="editSystemUserList(item)"> Edit </v-btn>
        <EidtSystemuser :visible="EditSystemUser" :item="editItem" @close="EditSystemUser = false" />
        <v-btn color="error" text @click="deleteSystem(item)"> Delete </v-btn>
      </template>
    </v-data-table>

     <v-dialog v-model="SystemDeleteModal" max-width="550">
		<v-card>
			<v-card-title class="text-h5"> Delete confirmation </v-card-title>
			<v-card-text> Are you sure you want to delete ? </v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn  text @click="SystemDeleteModal = false"> Cancel </v-btn>
				<v-btn color="primary" @click="deleteSystemUser(thisItemId)"> Confirm </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

    <v-dialog v-model="addUserModal" max-width="1000">
      <v-card>
        <v-form ref="form">
        <v-card-title class="justify-center">
          <span class="text-h5"><strong> Add System User</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field 
                  v-model="AddSystemUser.name" 
                  label="Name*" 
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field 
                  v-model="AddSystemUser.email" 
                  label="Email*" 
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field 
                  v-model="AddSystemUser.phone_number" 
                  label="Phone No.*" 
                  required
                  counter="10"
                  @keypress="onlyNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="AddSystemUser.district_name"
                  label="District*"
                  required
                  :items="districtList"
                  item-text="district_title"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete 
                  v-model="AddSystemUser.designation" 
                  label="Designation*" 
                  required 
                  :items="roleList"
                  item-text="role"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="AddSystemUser.hospital_name"
                  label="Eye Collection Center/Hospital*"
                  required
                  :items="centerList"
                  item-text="hospital_name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field 
                  v-model="AddSystemUser.password" 
                  label="Password*" 
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small class="ml-3">*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="blankAddModal()"> Cancel </v-btn>
          <v-btn color="primary" @click="addSystemUserList()" :disabled="!formIsValid"> Submit </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userStatusModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Delete confirmation </v-card-title>
        <v-card-text> Are you sure you want to delete ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="userStatusModal = false"> Cancel </v-btn>
          <v-btn color="primary" > Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import SystemService from '@/service/System.service'
import axios from 'axios'
import EidtSystemuser from './EditSystemUser.vue'
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const localStoragedata = JSON.parse(localStorage.getItem("token"))
const BaseUrl = "https://api.ebsr.in/users"
const token = localStoragedata.token

export default {
  components: {
		EidtSystemuser
	},

  data() {
    return {
      SystemDeleteModal:false,
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      editItem: '',
      thisItemId:'',
      timeout: 2000,

      headers: [
        // { text: 'USER ID', value: 'user_id', width: '150px' },
         { text: 'SystemUser Id', value: 'id', align: ' d-none' },
        { text: 'NAME', value: 'name', width: '150px' },
        { text: 'EMAIL', value: 'email', width: '150px' },
        { text: 'PHONE NO.', value: 'phone_number', width: '150px' },
        { text: 'DESIGNATION', value: 'designation', width: '150px' },
        { text: 'DISTRICT', value: 'district', width: '150px' },
        { text: 'EYE COLLECTION CENTER/HOSPITAL', value: 'hospital_name', width: '200px' },
        { text: 'STATUS', value: 'status', align: ' d-none' },
        { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
      addUserModal: false,
      userStatusModal: false,
      EditSystemUser: false,
      roleList: [{ role: ""}],
      centerList: [{ id: "", hospital_name: ""}],
      districtList: [{ district_title: ""}],

      SystemList: [],
      filter: {
        name: '',
        email: '',
        designation: '',
      },

      AddSystemUser: {
        name: '',
        email: '',
        phone_number: '',
        designation: '',
        district_name: '',
        hospital_name: '',
        password: '',
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.SystemUserList()
        this.GetDistrictList()
        this.GetDesginationList()
        this.GetHospitalList()
      },
      deep: true,
    },
  },
  
  computed: {
    formIsValid () {
      return (
        this.AddSystemUser.name &&
        this.AddSystemUser.email.match(validRegex) &&
        this.AddSystemUser.phone_number &&
        this.AddSystemUser.designation &&
        this.AddSystemUser.district_name &&
        this.AddSystemUser.hospital_name &&
        this.AddSystemUser.password
      )
    },
  },

  async mounted() {
    this.SystemUserList()
    this.GetDistrictList()
    this.GetDesginationList()
    this.GetHospitalList()
  },


  methods: {

    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9 ) {
          $event.preventDefault();
      }
    },

    editSystemUserList(e) {
			this.EditSystemUser = true
			this.editItem = e
		},

    deleteSystem(e) {
			this.SystemDeleteModal = true
			this.thisItemId = e.id
		},

    async SystemUserList() {
      this.listLoading = true
      
      const service = new SystemService()
      let response = await service.SystemList()

      if (response) {
        this.SystemList = response.data
        this.totallist = this.SystemList.length
        this.filter.name = ""
        this.filter.email = ""
        this.filter.designation = ""
      } else {
        this.SystemList = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    async GetDistrictList() {
      const service = new SystemService()
      let response = await service.DistrictList()
      if (response) {
        this.districtList = response.data
        // console.log("hwejkghrwer",this.districtList)
      } else {
        this.districtList = []
      }
    },

    async GetDesginationList() {
      const service = new SystemService()
      let response = await service.DesginationList()
      if (response) {
        this.roleList = response.data
      } else {
        this.roleList = []
      }
    },

    async GetHospitalList() {
      const service = new SystemService()
      let response = await service.HospitalList()

      if (response) {
        this.centerList = response.data
      } else {
        this.centerList = []
      }
    },

    async SystemUserSearchList(){
      this.listLoading = true

      if(this.filter.name || this.filter.email || this.filter.designation){
        const data = {
        name: this.filter.name,
        email: this.filter.email,
        designation: this.filter.designation,
      }
       try {
        const response = await axios.post(`${BaseUrl}/search`, data,{
          headers: {
            'Content-type': 'application/json',
             Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          this.SystemList = response.data.data
          this.totallist = this.SystemList.length
        } else {
          this.SystemList = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
      }else{
        
        this.snackbarText = "Kindly Enter The Keyword"
        this.snackbar = true
        return
      }

    
    },
    
    async addSystemUserList(){
     
      if(this.AddSystemUser.phone_number.length  < 10){
        return false,
        this.snackbarText = "Phone number should be 10 character.",
        this.snackbar = true
      }

      const data = {
        name: this.AddSystemUser.name,
        email: this.AddSystemUser.email,
        phone_number: this.AddSystemUser.phone_number,
        designation: this.AddSystemUser.designation,
        district: this.AddSystemUser.district_name,
        hospital_id: this.AddSystemUser.hospital_name,
        password: this.AddSystemUser.password,
      }
       try {
        const response = await axios.post(`${BaseUrl}/add_user`, data, {
          headers: {
            'Content-type': 'application/json',
             Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status == 201) {
          this.addUserModal = false
          this.snackbarText = response.data.message
          this.SystemUserList()
          this.AddSystemUser.name=''
          this.AddSystemUser.email=''
          this.AddSystemUser.phone_number=''
          this.AddSystemUser.designation=''
          this.AddSystemUser.district_name=''
          this.AddSystemUser.hospital_name=''
          this.AddSystemUser.password=''
        }else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async blankAddModal(){
      this.addUserModal = false
      this.SystemUserList()
      this.AddSystemUser.name=''
      this.AddSystemUser.email=''
      this.AddSystemUser.phone_number=''
      this.AddSystemUser.designation=''
      this.AddSystemUser.district_name=''
      this.AddSystemUser.hospital_name=''
      this.AddSystemUser.password=''
    },

    async deleteSystemUser(item) {
			const data = {
        id: item,
			}
			try {
				const response = await axios.post(`${BaseUrl}/delete`, data, {
					headers: {
						'Content-type': 'application/json',
						 Authorization: `Bearer ${token}`,
					},
				})
				if (response.data.status == 200) {
					this.snackbarText = response.data.message
          this.SystemUserList()
          this.SystemDeleteModal=false
				}else {
					this.snackbarText = response.data.message
				}
				this.snackbar = true
			} catch (e) {
				console.log(e)
			}
		},	

  },
   

}
</script>
